import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';

export const ExtendedTwirl = () => {
  const { RiveComponent } = useRive({
    src: '/animations/theleap.riv',
    stateMachines: 'CenterSlidingWiggler',
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.CenterLeft,
    }),
  });

  return (
    <div className="px-2 w-full">
      <RiveComponent className="w-full h-24 sm:h-48" />
    </div>
  );
};
