import { useRouteLoaderData } from '@remix-run/react';

// Couldn't figure out how to dynamically import these files based on location; since this
// is just to get us setup with the UI but not for long term, figured this would be okay in
// the interim (till we put in i18n). Looking to limit the changes required throughout
// the other pages for putting in i18n in the meantime.
import commonTranslations from '~/data/locale/en/common.json';

export interface TranslationObject {
  [key: string]: string;
}

export type TranslationArgs = { [name: string]: string | undefined };

export type TranslationFunction = (
  translationKey: string,
  args?: TranslationArgs
) => string;

export enum TranslationNamespace {
  root = 'root',
  default = 'default',
}

const translationsByNamespace: { [key: string]: TranslationObject } = {
  root: {
    ...commonTranslations,
  },
  default: {
    ...commonTranslations,
  },
};

export type GetTranslationArgs = {
  namespace?: TranslationNamespace;
  translationObject?: TranslationObject;
};

export type GetTranslationResult = { t: TranslationFunction };

export const getTranslationsObject = (
  namespace = TranslationNamespace.root
): TranslationObject => translationsByNamespace[namespace];

const getTranslations = ({
  namespace = TranslationNamespace.root,
  translationObject,
}: GetTranslationArgs): GetTranslationResult => {
  const translations = translationObject || translationsByNamespace[namespace];
  const defaultTranslations =
    translationsByNamespace[TranslationNamespace.default];
  return {
    t: (translationKey: string, args?: TranslationArgs) => {
      let translation =
        translations[translationKey] ||
        defaultTranslations[translationKey] ||
        translationKey;
      if (!args) {
        return translation;
      }
      for (const [k, v] of Object.entries(args)) {
        translation = translation.replace(`\${${k}}`, v || k);
      }
      return translation;
    },
  };
};

export const useLoadedTranslations = (
  namespace: TranslationNamespace
): { t: TranslationFunction } => {
  const routePath =
    namespace === TranslationNamespace.root ? namespace : `routes/${namespace}`;
  try {
    const routerData = useRouteLoaderData(routePath) || { t: {} };
    const { t } = routerData as { t: TranslationObject };
    return getTranslations({ translationObject: t });
  } catch (e) {
    return getTranslations({ namespace: TranslationNamespace.root });
  }
};

export default getTranslations;
