import { accountsBaseUrl, creatorAdminBaseUrl } from 'leap-auth';

import type { DataFunctionArgs } from '@remix-run/cloudflare';

export const creatorAdminUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/storefront`;
export const creatorAdminOnboardingUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/onboarding`;
export const creatorAdminBlockEditUrl =
  (blockId: string) => (args: DataFunctionArgs) =>
    `${creatorAdminBaseUrl(args)}/storefront/${blockId}`;

export type AppRoute =
  | '/login'
  | '/logout'
  | '/signup'
  | '/forgot-password'
  | '/recover-password';

export const loginPath = () => '/login';
export const logoutPath = () => '/logout';
export const registerPath = () => '/signup';
export const forgotPasswordPath = () => '/forgot-password';
export const recoverPasswordPath = () => '/recover-password';
export const authProviderPath = (provider: string) => `/auth/${provider}`;

export const recoverPasswordUrl = (args: DataFunctionArgs) =>
  `${accountsBaseUrl(args)}/recover-password`;

export const confirmEmailUrl = (args: DataFunctionArgs) =>
  `${accountsBaseUrl(args)}/confirm-email`;

export const authCallbackUrl = (args: DataFunctionArgs, provider: string) =>
  `${accountsBaseUrl(args)}/auth/${provider}/callback`;
