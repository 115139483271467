{
  "signup-title": "Claim your free creator store!",
  "signup-cta": "Sign me up",
  "signup-with": "Sign up with",
  "handle-field": "Username",
  "signin-title": "Welcome back to The Leap!",
  "signin-cta": "Let's go",
  "signin-generic-error": "Oops! We couldn't find an account with that combo.",
  "go-to-sign-in": "Already have an account?",
  "sign-in-link": "Sign in here!",
  "sign-up-link": "Sign up!",
  "go-to-sign-up": "Need an account?",
  "go-to-forgot-password": "New password?",
  "forgot-password-link": "Help is here.",
  "forgot-password-title": "Password recovery",
  "forgot-password-instructions": "Enter the email that you used when creating your account and we will send you everything you need to recover your password.",
  "forgot-password-title-sent": "Password recovery sent",
  "forgot-password-instructions-sent": "If ${email} matches with an account in our system, an email will be sent with details on how to reset your password.",
  "forgot-password-generic-error": "Oops! Something went wrong. Please try again later or contact us",
  "recover-password-title": "Reset password",
  "go-to-forgot-password-from-recover": "Link expired?",
  "forgot-password-new-link": "Get a new one here.",
  "go-to-sign-in-from-password-recovery": "Don't need help anymore?",
  "recover-cta": "Recover password",
  "email-field": "Email",
  "email-field-description": "We'll need this to send your list of leads and sales",
  "password-field": "Password",
  "password-confirmation-field": "Confirm Password",
  "password-confirmation-invalid": "Passwords do not match",
  "password-changed-successfully": "Password reset successfully!",
  "reset-cta": "Reset",
  "or-continue-with": "Or continue with",
  "something-went-wrong": "Something went wrong",
  "generic-error": "Oops! Something went wrong that we did't anticipate. Please try again in a bit!",
  "response-error-boundary-title": "Oh No!",
  "response-error-boundary-message": "Sorry, something went wrong. Please try again later or contact us. 🙃",
  "response-error-title": "Oops!",
  "server-line1": "Sorry to interrupt your flow, we're experiencing some hiccups 🙃",
  "server-line2": "We're working on a fix. In the meantime, we'll cool it on giving our computers soda.",
  "server-line3": "Please try again later, or you can",
  "contact-us": "contact us",
  "default-line1": "Something unexpected happened while processing your request. We apologize for the inconvenience 🙁",
  "default-line2": "Please double-check your request and try again or visit the Leap's homepage.",
  "400-line1": "Oops! Your request seems to be missing or has invalid data 🧐",
  "400-line2": "Please double-check your request and try again.",
  "401-line1": "Sorry, you can't access this resource 🔐",
  "401-line2": "Please make sure you're logged in with the appropriate credentials.",
  "403-line1": "You don't have permission to view this content 🚫",
  "403-line2": "Please double check the URL or visit the Leap's homepage.",
  "404-line1": "Looks like we didn't stick the landing on this leap! 🤸‍♀️",
  "404-line2": "We can't find the page you're looking for.",
  "404-line3": "Please double check the URL or visit the Leap's homepage.",
  "go-to-login-page": "Go to login page",
  "terms-of-service": "Terms of Service",
  "privacy-policy": "Privacy Policy",
  "signup-agreement": "By signing up, you agree to our",
  "and": "and",
  "invalid-confirm-email-token": "Invalid confirmation token. Please try again",
  "successfully-confirmed-email": "You have successfully confirmed your email.",
  "marketing-opt-in": "Yes, I want to receive helpful resources and updates from The Leap"
}
